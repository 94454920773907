import {Link, useParams} from '@remix-run/react';
import {Footer, FooterItem} from '~/ui/Footer';
import {useDictionary} from '../$lang/route';

export const PageFooter = () => {
  const {t} = useDictionary();
  const {lang} = useParams();
  return (
    <Footer>
      <FooterItem title={t(l => l.site)}>
        <Link to="/">{t(l => l.home)}</Link>
        <Link to="/en">en 🇬🇧</Link>
        <Link to="/fr">fr 🇫🇷</Link>
      </FooterItem>
      <div className="flex-[4] sm:justify-center flex flex-row">
        <FooterItem title={t(l => l.docs)} className="sm:translate-x-1/3">
          <Link to={(lang ? `/${lang}` : '') + '/docs/legal'}>{t(l => l.legal)}</Link>
          <Link to={(lang ? `/${lang}` : '') + '/docs/privacy'}>{t(l => l.privacy_policy)}</Link>
          <Link to={(lang ? `/${lang}` : '') + '/docs/terms'}>{t(l => l.terms_of_use)}</Link>
          <Link to={(lang ? `/${lang}` : '') + '/docs/terms-end-users'}>{t(l => l.end_user_license_agreement)}</Link>
        </FooterItem>
      </div>
      <FooterItem title={t(l => l.about)}>
        <Link to="mailto:contact@questovery.com">{t(l => l.contact_us)}</Link>
      </FooterItem>
    </Footer>
  );
};
