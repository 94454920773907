import type {ReactNode} from 'react';

import {cn} from '~/utils';
import type {Theme} from './Theme';

type FooterProps = {
  children?: ReactNode;
  color?: Theme.Color;
};

export const Footer = ({children, color = 'gray'}: FooterProps) => {
  return (
    <footer className={cn('w-full justify-between flex flex-col text-primary')}>
      <div className="pt-12 pb-4 px-32 flex sm:flex-row flex-col gap-x-32 gap-y-8">{children}</div>
      <p className="text-center text-xs py-4">Copyright © {new Date().getFullYear()} Lezo</p>
    </footer>
  );
};

type FooterItemProps = {
  title?: string;
  children?: ReactNode;
  className?: string;
};
export const FooterItem = ({children, title, className}: FooterItemProps) => {
  return (
    <div className={cn('flex flex-col space-y-2 font-semibold text-sm', className)}>
      {title && <h6 className="text-lg font-bold mb-4">{title}</h6>}
      {children}
    </div>
  );
};
